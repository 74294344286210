import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faClock,
  faCommentDots,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';

import Layout from '../components/layout';

const Contact = () => {
  var pageTitle = 'Contact Us';

  return (
    <Layout
      slug="contact"
      title={pageTitle}
      description="Learn more about our hours and how to contact us."
      noContainer
    >
      <div className="container mt-6 mb-20">
        <h1>{pageTitle}</h1>
        <h2>Need help or have feedback?</h2>
        <div className="flex flex-col md:flex-row md:flex-wrap justify-evenly items-center mb-10">
          <span className="text-center mb-4">
            <button
              className="btn btn-light mb-2"
              style={{ minWidth: 150 }}
              onClick={() => {
                window?.FB.CustomerChat.showDialog();
              }}
            >
              <FontAwesomeIcon
                icon={faCommentDots}
                size="2x"
                className="mb-2"
              />
              <br />
              Chat with us
            </button>
            <span className="block">via Facebook</span>
          </span>

          <span className="text-center mb-4">
            <a
              href="tel:403-740-4833"
              className="btn btn-light mb-2"
              style={{ minWidth: 150 }}
            >
              <FontAwesomeIcon icon={faPhoneAlt} size="2x" className="mb-2" />
              <br />
              Call or Text
            </a>
            <span className="block">(403) 740-4833</span>
          </span>

          <span className="text-center mb-4">
            <a
              href="mailto:laura.vipets@yahoo.ca"
              className="btn btn-light mb-2"
              style={{ minWidth: 150 }}
            >
              <FontAwesomeIcon icon={faEnvelope} size="2x" className="mb-2" />
              <br />
              Email
            </a>
            <span className="block">laura.vipets@yahoo.ca</span>
          </span>
        </div>

        <div className="flex flex-wrap">
          <div className="md:w-1/2 md:pr-3">
            <h2>
              <FontAwesomeIcon icon={faClock} /> Hours - Temporary Covid-19
              Hours
            </h2>
            <ul>
              <li>Sunday - 9am-2pm, 6:30pm-7:30pm</li>
              <li>Monday - 9am-2pm, 6:30pm-7:30pm</li>
              <li>Tuesday - 9am-2pm, 6:30pm-7:30pm</li>
              <li>Wednesday - 9am-2pm, 6:30pm-7:30pm</li>
              <li>Thursday - 9am-2pm, 6:30pm-7:30pm</li>
              <li>Friday - 9am-2pm, 6:30pm-7:30pm</li>
              <li>Saturday - 9am-2pm, 6:30pm-7:30pm</li>
            </ul>
          </div>

          <div className="md:w-1/2 md:pl-3">
            <h2>
              <FontAwesomeIcon icon={faClock} /> Holiday Hours
            </h2>
            <ul>
              <li>New years Day - 11am-2pm</li>
              <li>Easter Sunday - CLOSED</li>
              <li>Thanksgiving - 9am-12pm</li>
              <li>Christmas Eve - 9am-12pm</li>
              <li>Christmas Day - CLOSED</li>
              <li>New Year's Eve - 9am-2pm</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap text-center">
        <div className="w-full mb-6 lg:mb-0">
          <h2 className="!mb-1">Halkirk</h2>
          <span className="inline-block font-semibold mb-3">
            Boarding Kennel &amp; Grooming Salon
          </span>
          <iframe
            title="Halkirk Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.4590402429044!2d-112.09428804784041!3d52.36209597968533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x530b3fe904470997%3A0x19e728e6804e3c18!2sVery%20Important%20Pets%20Boarding%20Kennel%20%26%20Grooming%20Salon!5e0!3m2!1sen!2sus!4v1609270280581!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
          ></iframe>
        </div>
        {/* <div className="w-full lg:w-1/2">
          <h2 className="!mb-1">Stettler</h2>
          <span className="inline-block font-semibold mb-3">
            Grooming Salon
          </span>
          <iframe
            title="Stettler Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d152.40372482538922!2d-112.70464566206324!3d52.325807902044986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5374cc6f1977b50b%3A0x22cbe67d3f6bc5e3!2s4911%2050%20Ave%2C%20Stettler%2C%20AB%20T0C%202L2%2C%20Canada!5e0!3m2!1sen!2sus!4v1622685715947!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
          ></iframe>
        </div> */}
      </div>
    </Layout>
  );
};

export default Contact;
